import React from "react";

export default function SoftwareDeveloper() {
  return (
    <>
      <div className="careerComponents__jobDescription">
        <h1>Software Developer</h1>
        <h2>Job Description</h2>
        <p>
          We are looking for a qualified Software Developer to join our growing
          team!
        </p>
        <p>
          We seek a creative, skilled Software Developer to work with our
          designers to create the future of real estate development online. The
          Software Developer will work with other Web Developers to ensure that
          our applications are optimized for client use, leading the dev team
          and setting course of development for Robotic Imaging's software
          products. Responsibilities will include translation of the UI/UX
          design wireframes to actual code that will produce visual elements of
          the application. You will work with our full stack developers on
          technical implementation, taking an active role on both sides and
          defining how the application looks as well as how it works.
        </p>
        <ul>
          <li>Lead the existing developer team</li>
          <li>Develop new user-facing features</li>
          <li>Build reusable code and libraries for future use</li>
          <li>Ensure the technical feasibility of UI/UX designs</li>
          <li>Optimize application for maximum speed and scalability</li>
        </ul>

        <h2>Job Requirements</h2>
        <ul>
          <li>Minimum of two years working as a professional web developer</li>
          <li>
            Strong understanding of front-end coding and development
            technologies
          </li>
          <li>Important Languages: ReactJS, React Native, SASS</li>
          <li>Databases: Google Cloud Platform (Firebase, Firestore)</li>
          <li>
            Good understanding of SEO principles across mobile and desktop
          </li>
        </ul>

        <h2>About Robotic Imaging</h2>
        <p>
          Robotic Imaging is a real estate technology company equipping
          planners, designers, architects, engineers and construction teams with
          digital copies of their project. Robotic Imaging is headquartered in
          Philadelphia, PA. Our mission is to unleash the power of
          cyber-physical systems across the real estate industry for global
          stakeholders.
        </p>

        <p>Job Types: Full-time, Contract</p>
        {/* <p>
          Robotic Imaging is a construction-based technology company
              equipping architects, engineers and construction teams with
              realistic as-built environments. We specialize in efficient site
              solutions across the real estate market including; Retail,
              Industrial, Residential, Education, Healthcare and Historic.
              Robotic Imaging is headquartered in Philadelphia, PA and Park
              City, UT. Our team consists of reality capture technicians
              throughout the globe helping AEC teams adopt Building Information
              Modeling (BIM). Our mission is to make reality data accessible and
              practical for architects, engineers and construction firms of all
              sizes.
          </p> */}
      </div>
    </>
  );
}
