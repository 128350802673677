import React from "react";

export default function BIMModelingSpecialist() {
  return (
    <>
      <div className="careerComponents__jobDescription">
        <h1>BIM Modeling Specialist</h1>
        <h2>Job Description</h2>
        <p>
          We are looking for a qualified BIM Modeling Specialist to join our
          growing team! We are looking to add a talented BIM Modeling Specialist
          to support both our local team in Philadelphia, PA and network of BIM
          Surveyors. The primary responsibility of this position is to prepare
          all 2D and 3D models from 3D reality point clouds and meshes. This
          Revit and AutoCAD drafter would be responsible for reviewing,
          developing, and modifying diagrams, and product/architectural details
          to client title blocks and standards. This opportunity also requires
          the ability to manage and prioritize multiple projects, and clearly
          communicate project flow with management. This is a position that
          requires critical thinking, and offers opportunities to directly
          collaborate with the founding team.
        </p>
        <ul>
          <li>
            Participate in and execute our BIM modeling and 3D modeling
            initiatives.
          </li>
          <li>Able to modify existing drawings per client markups</li>
          <li>
            Maintain, create, and follow checklists to ensure consistency and
            quality of work as well as reviewing all work for prior to releasing
            as complete
          </li>
          <li>
            Provide prompt and accurate progress status reports on a regular
            basis
          </li>
          <li>
            Complete accurate and timely project time sheets accounting for time
            spent on each project
          </li>
          <li>
            Coordinate project requirements with team members and workflow
            management
          </li>
          <li>
            Revit and CAD drafting support for associates and project managers
            during the planning, design, and construction phases of various
            projects
          </li>
        </ul>

        <h2>Skills and Characteristics</h2>
        <ul>
          <li>
            Ability to read and interpret architectural and structural
            construction documents
          </li>
          <li>
            Must be a self-starter, and have the ability to work well
            independently and as part of a team
          </li>
          <li>
            Ability to use time productively, maximize efficiency, and meet
            challenging work goals
          </li>
          <li>Ability to take on additional responsibilities as needed</li>
          <li>
            Uphold company values and demand the highest standard of conduct
            from self and others
          </li>
        </ul>

        <h2>Job Requirements</h2>
        <ul>
          <li>Bachelor&apos;s Degree</li>
          <li>CAD or Revit experience (minimum 3 years)</li>
          <li>Autodesk Revit experience (minimum 3 years)</li>
          <li>Modeling from Point Cloud experience (minimum 2 years)</li>
          <li>Ability to create and modify Revit families and templates</li>
          <li>Revit model coordination</li>
          <li>Ability to prioritize detail in a fast-paced work environment</li>
          <li>Strong verbal and written communication skills</li>
          <li>Ability to learn point cloud software and workflows</li>
          <li>Proven ability to maintain confidentiality</li>
        </ul>

        <h2>About Robotic Imaging</h2>
        <p>
          Robotic Imaging is a real estate technology company equipping
          planners, designers, architects, engineers and construction teams with
          digital copies of their project. Robotic Imaging is headquartered in
          Philadelphia, PA. Our mission is to unleash the power of
          cyber-physical systems across the real estate industry for global
          stakeholders.
        </p>

        <p>Job Types: Part-time, Contract</p>
        {/* <p>
            Pay: Determined on a project basis depending upon the size, the
            scope of work, and level of detail required by the modeler.
          </p> */}
      </div>
    </>
  );
}
