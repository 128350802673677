import { styled } from "@mui/material";
import { breakpoint } from "../../components/styled-components/Breakpoints/breakpoints";

/* Styled Components for the Career page */
export const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Helvetica;

  @media (min-width: 1300px) {
  height: calc(100vh - 60px); //vh - nav and footer heights
  }

  > h2 {
    font-size: 30px;
    padding: 20px 0px 10px;
  }

  > span {
    font-size: 20px;
    margin: 0 auto;
    color: #ffb310;

    ${breakpoint(
      "small-",
      `
    font-size: 18px;
  `
    )}
  }
`;

export const Row = styled("div")`
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  justify-content: center;

  ${breakpoint(
    "medium-",
    `
    flex-wrap: auto;
    justify-content: center;
  `
  )}

  ${breakpoint(
    "large+",
    `
    padding-top: 20px;
  `
  )}
`;
