import React from "react";

export default function RealityModelingInternship() {
  return (
    <>
      <div className="careerComponents__jobDescription">
        <h1>Reality Modeling Internship</h1>
        <h2>Job Description</h2>
        <p>
          We are looking for leaders ready to learn our workflows. A qualified
          technician with experience and desire to learn cutting edge reality
          applications: LiDAR, AR, VR, 3D.
        </p>
        <ul>
          <li>Work with our core team to develop standards and procedures</li>
          <li>Manage Robotic Imaging Application Database</li>
          <li>
            Must be a self-starter, and have the ability to work well
            independently and as part of a team
          </li>
          <li>Ability to take on additional responsibilities as needed</li>
          <li>
            Passionate about reality capture and how it permeates multiple
            industries
          </li>
        </ul>

        <h2>About Robotic Imaging</h2>
        <p>
          Robotic Imaging is a real estate technology company equipping
          planners, designers, architects, engineers and construction teams with
          digital copies of their project. Robotic Imaging is headquartered in
          Philadelphia, PA. Our mission is to unleash the power of
          cyber-physical systems across the real estate industry for global
          stakeholders.
        </p>

        <p>Job Types: Full-time, Part-time, Contract</p>
      </div>
    </>
  );
}
