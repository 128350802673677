import React, { useState } from "react";
import * as Yup from "yup";
import { phoneRegExp } from "../../../utils/functions";
import { Formik, Form, Field, FastField } from "formik";
import axios from "axios";
import Dropzone from "react-dropzone";
import ReCAPTCHA from "react-google-recaptcha";
import { mailingList } from "../../../utils/vars";

export default function CareerForm() {
  const [uploadedAttachments, setUploadedAttachments] = useState<File[]>([]);
  const [touchedAttachments, setTouchedAttachments] = useState<boolean>(false);

  const signupSchema = Yup.object().shape({
    name: Yup.string().min(2, "Enter your name").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    position: Yup.string()
      .min(5, "Enter the position you're interested in")
      .required("Required"),
    attachments: Yup.array()
      .of(Yup.mixed())
      .min(1, "Required")
      .test(
        "fileSize",
        "9 MB maximum for file uploads",
        function (files: File[] | undefined) {
          let totalSize = 0;
          files?.forEach((file) => (totalSize += file.size));
          return totalSize < 9 * 1024 * 1024;
        }
      ),
    recaptcha: Yup.string()
      .nullable()
      .required("Please confirm that you are not a robot"),
  });

  return (
    <div className="careerComponents__form">
      <div className="careerComponents__formHeader">
        <span>
          If you're interested in joining the Robotic Imaging team, fill in your
          information and upload your resume. We'll get back to you ASAP.
        </span>
      </div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          position: "",
          attachments: [] as File[],
          recaptcha: "",
          success: false,
        }}
        validationSchema={signupSchema}
        onSubmit={async (
          { name, email, phone, position, attachments, recaptcha },
          { setFieldValue, resetForm }
        ) => {
          const formData = new FormData();
          formData.append("destination", email);
          formData.append("bcc", JSON.stringify(mailingList));
          formData.append("subject", "Robotic Imaging Career Application");
          if (attachments.length > 0) {
            attachments.forEach((file: File) => {
              formData.append("attachments", file, file.name);
            });
          }
          formData.append("recaptcha", recaptcha);
          formData.append(
            "text",
            `
              Thanks for reaching out to Robotic Imaging! We'll get back to you with a follow-up and more information as soon as possible!

              Here's a copy of the information you've sent to us:
              Name: ${name}
              Email: ${email}
              Phone: ${phone}
              Desired Position: ${position}
            `
          );
          formData.append(
            "html",
            `
              <div>Thanks for reaching out to Robotic Imaging! We'll get back to you with a follow-up and more information as soon as possible!</div>
              <br />
              <div>Here's a copy of the information you've sent to us:</div>
              <div>Name: ${name}</div>
              <div>Email: ${email}</div>
              <div>Phone: ${phone}</div>
              <div>Desired Position: ${position}</div>
            `
          );

          axios
            .post(
              `${process.env.GATSBY_firebase_function_domain}/smtpMailers-sendMailWithAttachmentsV2`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
          setFieldValue("success", true);
          setTimeout(() => {
            resetForm();
            setUploadedAttachments([]);
            setTouchedAttachments(false);
          }, 10000);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => {
          const attachmentErrors =
            typeof errors.attachments === "string"
              ? errors.attachments
              : Array.isArray(errors.attachments)
              ? errors.attachments.map((err) => err.toString()).join(", ")
              : "";

          return (
            <Form
              className="career-request-form"
              encType="multipart/form-data"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              <div className="career-request-form_left-column">
                <div className="career-request-form__input-row">
                  <label htmlFor="name" className="career-request-form__label">
                    Name
                  </label>
                  <Field
                    name="name"
                    type="text"
                    placeholder="John Smith"
                    style={{ border: "1px solid black", borderRadius: "10px" }}
                    className="career-request-form__text-input"
                  />
                  {errors.name && touched.name ? (
                    <div className="requestForm__errors">{errors.name}</div>
                  ) : null}
                </div>

                <div className="career-request-form__input-row">
                  <label htmlFor="email" className="career-request-form__label">
                    Email
                  </label>
                  <Field
                    name="email"
                    type="text"
                    placeholder="email@address.com"
                    style={{ border: "1px solid black", borderRadius: "10px" }}
                    className="career-request-form__text-input"
                  />
                  {errors.email && touched.email ? (
                    <div className="requestForm__errors">{errors.email}</div>
                  ) : null}
                </div>

                <div className="career-request-form__input-row">
                  <label htmlFor="phone" className="career-request-form__label">
                    Phone
                  </label>
                  <Field
                    name="phone"
                    type="text"
                    placeholder="123-456-7890"
                    style={{ border: "1px solid black", borderRadius: "10px" }}
                    className="career-request-form__text-input"
                  />
                  {errors.phone && touched.phone ? (
                    <div className="requestForm__errors">{errors.phone}</div>
                  ) : null}
                </div>
                <div className="career-request-form__input-row">
                  <label
                    htmlFor="position"
                    className="career-request-form__label"
                  >
                    Desired Position
                  </label>
                  <Field
                    name="position"
                    type="text"
                    placeholder="Reality Capture Technician"
                    style={{ border: "1px solid black", borderRadius: "10px" }}
                    className="career-request-form__text-input"
                  />
                  {errors.position && touched.position ? (
                    <div className="requestForm__errors">{errors.position}</div>
                  ) : null}
                </div>
              </div>
              <div className="career-request-form_left-column">
                <div className="career-request-form__input-row">
                  <label
                    htmlFor="attachments"
                    className="career-request-form__label__attachment-label"
                  >
                    Attachments
                  </label>
                  <Dropzone
                    onDrop={(files: File[]) => {
                      setFieldValue("attachments", [...files]);
                      setUploadedAttachments(files);
                      setTouchedAttachments(true);
                    }}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <div
                        style={{ borderRadius: "20px", width: "100%" }}
                        {...getRootProps()}
                        className={`dropzone${
                          isDragActive ? " dropzone-active" : ""
                        }`}
                      >
                        <input
                          {...getInputProps()}
                          style={{ fontSize: "12px" }}
                        />
                        {!uploadedAttachments.length ? (
                          <span style={{ fontSize: "12px" }}>
                            Click or drag and drop your files here...
                          </span>
                        ) : (
                          uploadedAttachments[0].name +
                          (uploadedAttachments.length > 1
                            ? ` and ${
                                uploadedAttachments.length - 1
                              } other file(s)`
                            : "")
                        )}
                      </div>
                    )}
                  </Dropzone>
                  {errors.attachments && touchedAttachments ? (
                    <div className="requestForm__errors">
                      {attachmentErrors}
                    </div>
                  ) : null}
                </div>
                {values.success ? (
                  <div>
                    Form submitted succesfully! Resetting form fields in 10
                    seconds...
                  </div>
                ) : (
                  <div className="career-request-form__button-container">
                    <FastField
                      component={ReCAPTCHA}
                      sitekey={process.env.GATSBY_recaptchaSiteKey}
                      name="recaptcha"
                      onChange={(value: any) => {
                        setFieldValue("recaptcha", value);
                      }}
                    />
                    {errors.recaptcha && touched.recaptcha ? (
                      <div className="requestForm__errors">
                        {errors.recaptcha}
                      </div>
                    ) : null}

                    <button
                      type="submit"
                      className="btn career-request-form__submit-button"
                      onClick={() => setTouchedAttachments(true)}
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
