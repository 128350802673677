import React from "react";

export default function AccountManager() {
  return (
    <>
      <div className="careerComponents__jobDescription">
        <h1> Account Manager </h1>
        <h2>Job Description</h2>
        <p>
          We are searching for a driven Account Manager to join our dynamic,
          growing team. The individual should have a clear understanding of the
          components of the products and the ability to demonstrate how they
          work. Your responsibilities will include establishing the needs of the
          customer and recommending our services / products while persistently
          endorsing our services to potential clients.{" "}
          {/* <b>
              This job includes the opportunity for substantial commission
              payments.
            </b> */}
        </p>
        <p>
          You should be able to develop an excellent rapport with existing and
          new clients. A top-tier Account Manager should be knowledgeable about
          competing services and have the ability to break down technical
          terminology to non-technical clients, looking for our services.
          Responsibilities include:
        </p>
        <ul>
          <li>
            Establishing the needs of a customer and suggesting the appropriate
            products / services
          </li>
          <li>
            Explaining the more complex, technical information that Robotic
            Imaging uses to customers in a way that is easily understandable
          </li>
          <li>Following up with clients and resolving any issues</li>
          <li>Ensuring that sales targets are consistently met</li>
          <li>Reporting to management about sales made</li>
        </ul>

        <h2>Job Requirements</h2>
        <ul>
          <li>
            {"A bachelor's degree in business, or a related field is preferred"}
          </li>
          <li>Strong interpersonal and communication skills</li>
          <li>The ability to identify and follow up on leads</li>
          <li>
            Technical knowledge and a comprehensive understanding of how the
            company products / services work
          </li>
          <li>Knowledge of sales promotion techniques</li>
        </ul>

        <p>Job Type: Full-time</p>
        {/* <p>Pay: From $45,000.00 per year</p> */}
        <p>Benefits:</p>
        <ul>
          <li>Dental insurance</li>
          <li>Health insurance</li>
          <li>Vision insurance</li>
        </ul>
        <p>Schdeule: Monday to Friday</p>
        {/* <p>Supplemental pay types: Commission pay</p> */}
        <p>Ability to commute/relocate:</p>
        <ul>
          <li>
            Philadelphia, PA 19103: Reliably commute or willing to relocate with
            an employer-provided relocation package (Required)
          </li>
          <li>New York City</li>
        </ul>
      </div>
    </>
  );
}
