import React from "react";

export default function RealityCaptureTechnician() {
  return (
    <>
      <div className="careerComponents__jobDescription">
        <h1>Reality Capture Technician</h1>
        <h2>Job Description</h2>
        <p>
          We are seeking a motivated Reality Capture Technician to deploy out of
          our headquarters in Center City Philadelphia. The successful candidate
          will be responsible for capturing and registering 3D data using laser
          scanning technology to create accurate digital models of buildings,
          landscapes, and other structures. The Reality Capture Technician will
          work closely with our modeling and operations team to ensure timelines
          are met.
        </p>

        <h2>Duties & Essential Job Functions:</h2>
        <ul>
          <li>
            Operate 3D laser scanning equipment to capture site measurements for
            a wide variety of real estate projects
          </li>
          <li>
            Process and analyze data using software such as Cyclone, Revit, CAD,
            ReCap, Civil 3D, Autodesk, or similar programs
          </li>
          <li>
            Significant upside potential to grow and build a team under the
            brand that is receiving significant current demand for survey work
          </li>
          <li>
            Willing to travel on short notice to maintain relationships with
            clients and vendors
          </li>
          <li>Commission schedule eligibility for new business</li>
        </ul>

        <h2>Basic Qualifications:</h2>
        <ul>
          <li>
            1-3 years experience in Surveying, Architecture, Engineering, or
            Construction preferred
          </li>
          <li>
            Experience with reality capture technology or registration software
          </li>
          <li>Strong interest in working with a startup company</li>
          <li>
            Proficient with technology including smart phones, computers and
            tablets
          </li>
          <li>Ability to work outdoors and walk as needed</li>
        </ul>

        <p>
          <strong>Job Type:&nbsp;</strong>
          <span> Full-Time</span>
        </p>

        {/* <p>
            <strong>Base Salary:&nbsp;</strong>
            <span> $50,000 - $60,000 + Commission</span>
          </p> */}

        <p>
          <strong>Schedule:&nbsp;</strong>
          <span> 40 hour week (Monday - Friday)</span>
        </p>

        <p>
          <strong>Work Location:&nbsp;</strong>
          <span> Philadelphia HQ / On the road</span>
        </p>

        <h2>Benefits:</h2>
        <ul>
          <li>Dental Insurance</li>
          <li>Health Insurance</li>
          <li>Relocation Insurance</li>
          <li>Vision Insurance</li>
        </ul>

        <h2>About Robotic Imaging</h2>
        <p>
          Robotic Imaging is a real estate technology company equipping
          planners, designers, architects, engineers and construction teams with
          digital copies of their project. Robotic Imaging is headquartered in
          Philadelphia, PA. Our mission is to unleash the power of
          cyber-physical systems across the real estate industry for global
          stakeholders.
        </p>
      </div>
    </>
  );
}
