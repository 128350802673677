import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import CareerForm from "../page-components/careers/career-form/CareerForm";
import BIMModelingSpecialist from "../page-components/careers/job-descriptions/BIMModelingSpecialist";
import RealityCaptureTechnician from "../page-components/careers/job-descriptions/RealityCaptureTechnician";
import RealityModelingInternship from "../page-components/careers/job-descriptions/RealityModelingInternship";
import AccountManager from "../page-components/careers/job-descriptions/AccountManager";
import SoftwareDeveloper from "../page-components/careers/job-descriptions/SoftwareDeveloper";
import { ACTIVE_PAGE } from "../components/nav/utils";
import { MainContainer, Row } from "../page-components/careers/styles";

export default function CareersPage() {
  const [currentCareer, setCareer] = React.useState("");

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.CAREERS}>
      <Seo
        title="Careers | Robotic Imaging"
        description="Learn more about becoming part of the Robotic Imaging team."
      />
      <header className="site__header">
        {/* <h1 className="header__motto header__motto--no-margin">Careers</h1> */}
      </header>
      <MainContainer>
        <h2>We're Hiring!</h2>
        <span>Check out our open roles below.</span>

        <Row>
          <button
            onClick={() =>
              setCareer((prev) => {
                if (prev === "Reality Modeling Internship") return "";
                else return "Reality Modeling Internship";
              })
            }
            className="careerComponents__button"
          >
            <h1>Reality Modeling Internship</h1>
          </button>
          <button
            onClick={() =>
              setCareer((prev) => {
                if (prev === "Reality Capture Technician") return "";
                else return "Reality Capture Technician";
              })
            }
            className="careerComponents__button"
          >
            <h1>Reality Capture Technician</h1>
          </button>
          <button
            onClick={() =>
              setCareer((prev) => {
                if (prev === "BIM Modeling Specialist") return "";
                else return "BIM Modeling Specialist";
              })
            }
            className="careerComponents__button"
          >
            <h1>BIM Modeling Specialist</h1>
          </button>
          <button
            onClick={() =>
              setCareer((prev) => {
                if (prev === "Account Manager") return "";
                else return "Account Manager";
              })
            }
            className="careerComponents__button"
          >
            <h1>Account Manager</h1>
          </button>
          <button
            onClick={() =>
              setCareer((prev) => {
                if (prev === "Software Developer") return "";
                else return "Software Developer";
              })
            }
            className="careerComponents__button"
          >
            <h1>Software Developer</h1>
          </button>
        </Row>

        <Description currentCareer={currentCareer} />

        <CareerForm />
      </MainContainer>
    </SiteWrapper>
  );
}

const Description = ({ currentCareer }: { currentCareer: string }) => {
  switch (currentCareer) {
    case "Reality Modeling Internship":
      return <RealityModelingInternship />;
    case "Reality Capture Technician":
      return <RealityCaptureTechnician />;
    case "BIM Modeling Specialist":
      return <BIMModelingSpecialist />;
    case "Account Manager":
      return <AccountManager />;
    case "Software Developer":
      return <SoftwareDeveloper />;
    default:
      return null;
  }
};
